import {
    NotificationManager
} from "react-notifications";
import { getClientUploader, checkClientUploader } from "./clientUploaderApiConnector";

const storage = window.localStorage;

const getClientId = async () => {
    const clientUploaderId = storage.getItem("uploader_id");

    if (!clientUploaderId) {
        try {
            NotificationManager.info("Generuje se váš unikátní identifikační kód!");
            const clientUploader = await getClientUploader();
            if (!clientUploader) {
                throw new Error("Unable to generate your token");
            }
            storage.setItem("uploader_id", clientUploader.id);
            return clientUploader.id;
        } catch (e) {
            NotificationManager.error("Váš unikátní identifikační kód se nepodařilo vytvořit!");
        }
    } else {
        try {
            const status = await checkClientUploader(clientUploaderId);
            if (status !== 200) {
                throw new Error("Bad return status");
            }
            return clientUploaderId;
        } catch (e) {
            NotificationManager.error("Váš unikátní identifikační kód se nepodařilo ověřit!");
            storage.removeItem("uploader_id");
            return await getClientId();
        }
    }
    return null;
};

export default getClientId;