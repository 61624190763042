import React, { useState, useRef } from "react";
import "./Dropzone.css";

const Dropzone = props => {
    const [state, setState] = useState({ hightlight: false });
    const fileInputRef = useRef(null);

    const fileListToArray = list => {
        const array = [];
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    };

    const openFileDialog = () => {
        if (props.disabled) return;
        fileInputRef.current.click();
    };

    const onFilesAdded = evt => {
        if (props.disabled) return;
        const files = evt.target.files;
        if (props.onFilesAdded) {
            const array = fileListToArray(files);
            props.onFilesAdded(array);
        }
    };

    const onDragOver = event => {
        event.preventDefault();
        if (props.disabled) return;
        setState({ ...state, hightlight: true });
    };

    const onDragLeave = event => {
        setState({ ...state, hightlight: false });
    };

    const onDrop = event => {
        event.preventDefault();
        if (props.disabled) return;
        const files = event.dataTransfer.files;
        if (props.onFilesAdded) {
            const array = fileListToArray(files);
            props.onFilesAdded(array);
        }
        setState({ ...state, hightlight: false });
    };

    return (
        <div
            className={`dropzone ${state.hightlight ? "highlight" : ""}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={openFileDialog}
            style={{ cursor: props.disabled ? "default" : "pointer" }}
        >
            <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                multiple
                onChange={onFilesAdded}
            />
            <div className="upload-icon">
                <img
                    alt="upload"
                    className="icon"
                    src="baseline-cloud_upload-24px.svg"
                />
                <h4>Soubory přetáhněte sem</h4>
            </div>
            {props.children}
        </div>
    );
};

export default Dropzone;
