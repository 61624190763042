import React, { useState, useEffect } from "react";
import getClientUploaderPackages from "./getClientUploaderPackages";
import "./filePackageList.css";
import { NotificationManager } from "react-notifications";
import Loader from "../loader/loader";

const FileList = ({ uploaderId, update }) => {
    const [clientUploaderPackages, setClientUploaderPackages] = useState([]);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        (async () => {
            if (uploaderId) {
                try {
                    const data = await getClientUploaderPackages(uploaderId);
                    setClientUploaderPackages(data);
                    NotificationManager.info(
                        "Vaše již odeslané soubory byly úspěšně načteny"
                    );
                } catch (e) {
                    NotificationManager.error(
                        "Chyba pří načítání vašich již odeslaných souborů"
                    );
                }
                setDisabled(false);
            }
        })();
    }, [uploaderId, update]);

    return (
        <div className="file-packages">
            {clientUploaderPackages.map((pkg, i) => (
                <div className="file-package" key={i}>
                    <h5>
                        {pkg.description} <small>{pkg.createdAt}</small>
                    </h5>
                    <ul>
                        {pkg.files.map((file, j) => (
                            <li key={j}>
                                    {file}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
            <Loader display={disabled} />
        </div>
    );
};

export default FileList;
