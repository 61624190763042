import routes from "./../routes";
import axios from "axios";

export const getClientUploader = async () => {
    const response = await axios.get(routes.tokenCreate);
    return response.data;
};

export const checkClientUploader = async clientUploaderId => {
    const response = await axios.get(routes.tokenCheck, {
        params: {
            id: clientUploaderId
        }
    });
    return response.status;
};