const r = {
    tokenCheck: "/api/client_files/client_uploader/check",
    tokenCreate: "/api/client_files/client_uploader/create"
};

export const host = "https://uloziste.tiskarnahustopece.cz";

const attachHost = () => {
    const res ={};
    for (const property in r){
        res[property] = host + r[property];
    }
    return res;
};

export default attachHost();
