import React from "react";

const Loader = ({ display, children }) => {
    const classes = "overlay " + (display ? "display" : "");
    return (
        <div className={classes}>
            <div className="overlay-inner">
                <div><div className="lds-facebook"><div></div><div></div><div></div></div></div>
                {children}
            </div>
        </div>
    );
};

export default Loader;
