import { host } from "./../routes";
import axios from "axios";

const createClientUploaderPackage = async (formData, target, clientUploader) => {
    const url = `${host}/api/client_files/client_uploader/${clientUploader}/client_uploader_package/create` + (target ? "?target=" + target: "");
    const response = await axios.post(
        url,
        formData
    );
    return response.data;
};

export default createClientUploaderPackage;