import axios from "axios";
import { host } from "./../routes";

const getClientUploaderPackages = async id => {
    const response = await axios.get(
        `${host}/api/client_files/client_uploader/${id}/client_ploader_package/list`
    );
    return response.data;
};

export default getClientUploaderPackages;
